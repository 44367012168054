<template>
 <div id="riseafterlayoff">
        <!-- Preloader Start -->
        <div class="preloader">
            <div class="loading-container">
                <div class="loading"></div>
                <div id="loading-icon"><img
                        src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/Ingomu-Logo-White.png" alt="">
                </div>
            </div>
        </div>
        <!-- Preloader End -->

                <!-- Header Start -->
                <SiteHeader></SiteHeader>
        <!-- Header End -->

        <!-- Page Header Start -->
        <div class="page-header bg-section parallaxie">
            <!-- Page Header Box Start -->
            <div class="page-header-box">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <!-- Page Header Content Start -->
                            <div class="page-header-content">
                                <nav class="wow fadeInUp" data-wow-delay="0.25s">
                                    <ol class="breadcrumb">
                                        <h1 class="wow fadeInUp fs-50">Navigate Job Loss or Fear of Job Loss with<br><span>Free Career Coaching and Personal Development</span></h1>
                                        <li class="breadcrumb-item">Four weekly virtual group sessions, beginning Wednesday, March 26, at 11:00 AM Eastern Time</li>
                                    </ol>
                                </nav>
                            </div>
                            <!-- Page Header Content End -->
                        </div>
                    </div>
                </div>
            </div>
            <!-- Page Header Box End -->
        </div>
        <!-- Page Header End -->

                <!-- Page Single Post Start -->
                <div class="page-single-post">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <!-- Post Single Content Start -->
                        <div class="post-content">
                            <!-- Post Entry Start -->
                            <div class="post-entry">
                                <div>
                                   <p class="text-header-large">Laid Off? Let's Get You Back on Track—Fast!</p> 
                                   <p class="standard-text fw-b">Join our free expert-led coaching series to rebuild confidence, refine your job search strategy, and land your next opportunity.</p>
                                   <p class="standard-text">Four weekly virtual group sessions, beginning Wednesday, March 26, at 11:00 AM Eastern Time.</p>
                                   <p class="standard-text">As thousands of federal employees face unexpected job loss and those in fear of being laid off, Ingomu, a leading coaching and personal development platform, is stepping up to offer free career transition support—ensuring that those affected have the tools, guidance, and confidence to land their next opportunity.</p>
                                   <p class="text-header-large">Empowering Federal Workers with Free Coaching & Personal Development Resources</p>
                                   <p class="standard-text">Through this virtual initiative, laid-off federal employees and those in fear of being laid off will receive free access to career transition coaching, and personal development designed to help them re-enter the workforce with confidence. The program includes:</p>
                                   <p class="standard-text">
                                    ● <b>Career and Job Search Session, 3/26</b> - Expert guidance on resume building, interview prep, job searching, and personal branding.<br>
                                    ● <b>Emotional Resilience and Mindset Support, 4/2</b> - Group coaching to navigate stress, uncertainty, and career pivots with confidence.<br>
                                    ● <b>Financial Session, 4/9</b> - Guidance on how you can understand your financial situation and plan ahead.<br>
                                    ● <b>Networking and Personal Branding Guidance, 4/16</b> - Guidance on how to network and connect with hiring professionals.
                                </p>
                                   <p class="text-header-large text-center text-header-xl mtb-30"><a href="https://forms.gle/BTcwzWPAzZMNZZqQ7" class="color-ingomublue" target="_blank">SIGN UP TODAY</a></p>
                                   <p class="text-header-large">Why Attend?</p>
                                   <p class="standard-text">
                                    Lost your job or think you may be losing your job and unsure what's next?<br>
                                    Struggling with your resume or job search?<br>
                                    Feeling overwhelmed and needing expert guidance?
                                </p>
                                   <p class="standard-text"><b>At this event, our top coaches will help you:</b></p>
                                   <p class="standard-text">
                                    ● Rebuild confidence and stay motivated;<br>
                                    ● Optimize your job search strategies;<br>
                                    ● Discover how to network;<br>
                                    ● Understand your financial situation and how to plan ahead;
                                </p>
                                   <p class="text-header-large">Why Ingomu is Taking Action</p>
                                   <p class="standard-text">Ingomu's mission has always been to empower individuals through coaching, helping them unlock their potential in times of change. By offering this free support to federal workers, the company is taking a stand for those who have spent their careers serving others.</p>
                                   <p class="standard-text"><i>“As a nation, we rely on these workers to keep our government running. Now, it's our turn to support them,”</i> said Wynant, Co-Founder of Ingomu. <i>“Our coaches are ready to help them turn this setback into a fresh start.”</i></p>
                                   <p class="text-header-large">Your Next Career Move Starts Here!</p>
                                   <p class="standard-text">Don't miss out—register free and take control of your future.</p>
                                   <p class="text-header-large text-center text-header-xl mtb-30"><a href="https://forms.gle/BTcwzWPAzZMNZZqQ7" class="color-ingomublue" target="_blank">SIGN UP TODAY</a></p>
                                   <p class="standard-text">Questions? Contact us <router-link :to="{ name: 'contact' }" class="color-ingomublue">here</router-link>.</p>
                                </div>
                            </div>
                            <!-- Post Entry End -->

                              <!-- Post Tag Links Start -->
                              <div class="post-tag-links">
                                <div class="row align-items-center">
                                    <div class="col-lg-8"></div>

                                    <div class="col-lg-4">
                                        <!-- Post Social Links Start -->
                                        <div class="post-social-sharing wow fadeInUp" data-wow-delay="0.5s">
                                            <ul>
                                                <li><a href="https://www.linkedin.com/company/ingomu" target="_blank"><i
                                                            class="fa-brands fa-linkedin-in"></i></a></li>
                                                <li><a href="https://www.instagram.com/ingomu.coaching/"
                                                        target="_blank"><i class="fa-brands fa-instagram"></i></a></li>
                                                <li><a href="https://www.youtube.com/@ingomu" target="_blank"><i
                                                            class="fa-brands fa-youtube"></i></a></li>
                                                <li><a href="https://www.facebook.com/ingomu.coaching/"
                                                        target="_blank"><i class="fa-brands fa-facebook-f"></i></a></li>
                                                <li><a href="https://www.pinterest.com/ingomu_coaching/"><i
                                                            class="fa-brands fa-pinterest"></i></a></li>
                                            </ul>
                                        </div>
                                        <!-- Post Social Links End -->
                                    </div>
                                </div>
                            </div>
                            <!-- Post Tag Links End -->
                        </div>
                        <!-- Post Single Content End -->
                    </div>
                </div>
            </div>
        </div>
        <!-- Page Single Post End -->

 <!-- Footer Start -->
 <SiteFooter></SiteFooter>
        <!-- Footer End -->
</div>
</template>

<script>
import SiteHeader from "@/components/RiseSiteHeader";
import SiteFooter from "@/components/InfineFooter";

export default {
    name: "riseafterlayoff",
    components: {
        SiteHeader,
        SiteFooter
    },
    mounted() {

        $(function () {
            /* Preloader Effect */
            setTimeout(function () {
                $(".preloader").fadeOut(600);
            }, 600);

        })
    }
}
</script>

<style scoped>
.page-header {
    background: url(https://ingomucdn.s3.us-east-1.amazonaws.com/image/rise-after-layoff-web.png);
}

.standard-text {
font-size: 12pt;
    font-family: Arial, sans-serif;
    color: #000000;
    background-color: transparent;
    font-weight: 400;
    font-style: normal;
    font-variant: normal;
    text-decoration: none;
    vertical-align: baseline;
    white-space: pre-wrap;
}

.text-header-large {
    font-size: 14pt;
    font-family: Arial, sans-serif;
    color: #3b5998;
    background-color: transparent;
    font-weight: bold;
    font-style: normal;
    font-variant: normal;
    text-decoration: none;
    vertical-align: baseline;
    white-space: pre-wrap;
}

.text-header-xl {
    font-size: 20pt;
    font-weight: bold;
}

.color-ingomublue {
    color: #3b5998;
}

.italics {
    font-style: italic;
}

.fs-50 {
    font-size: 50px;
}

.page-single-post {
    padding: 50px 0;
}

.fw-b {
    font-weight: bold;
}

.post-entry p {
    margin-bottom: 15px;
}

.mtb-30 {
    margin-top: 30px;
    margin-bottom: 30px;
}

@media (max-width:480px) {
    .page-header {
        background: url(https://ingomucdn.s3.us-east-1.amazonaws.com/image/rise-after-layoff-mobile.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }
}
</style>